import { instanceToPlain, Transform, Type } from 'class-transformer'
import dayjs, { Dayjs } from 'dayjs'
import { Entity } from '..'

import {
  Agreement,
  BankData,
  CompanyType,
  ContactPerson,
  Gender,
  LaborData,
  MaritalStatus,
  PersonAccount,
  PersonAddress,
  PersonType,
  ReferencePerson,
  ResponsibleAgreement,
} from '.'
import { Lead } from '../crm'
import { AutoOwner, Country } from '../public'
import { Employee } from '../hr'
import { AppraisalExternalOffer, AppraisalMarketPrice } from '../purchase'
import { Concessionaire } from '../vehicle'
import { Evaluation, Financing } from '../loans'
import { SaleOrder } from '../sales'

export class Email {
  personal?: string
  work?: string

  get serialized () {
    return JSON.stringify(instanceToPlain(this))
  }

  toString () {
    const { personal, work } = this
    return personal || work
  }
}

export class Phone {
  work?: string

  toString () {
    return JSON.stringify(instanceToPlain(this))
  }
}

export class Photo {
  main: string

  toString () {
    const { main } = this
    return main
  }
}

class Schedule {
  day: string;
  start: string;
  end: string;

  isValid (): boolean {
    return !!this.day && !!this.start && !!this.end
  }

  toString (): string {
    return `${this.day}: ${this.start} - ${this.end}`
  }
}

class socialNetworkFields {
  name: string;
  link: string;
}

class socialNetwork {
  facebook: socialNetworkFields;
  instagram: socialNetworkFields;
  x: socialNetworkFields;
  linkedin: socialNetworkFields;
  tiktok: socialNetworkFields;
}

class personMetadata {
  @Type(() => Schedule)
  schedule: Schedule[] = [];

  @Type(() => socialNetwork)
  socialNetwork: socialNetwork
}

export class Person extends Entity {
  @Type(() => personMetadata)
  metadata: personMetadata

  @Type(() => PersonType)
  type: PersonType;

  @Type(() => CompanyType)
  companyType?: CompanyType;

  @Type(() => Country)
  country?: Country;

  @Type(() => Gender)
  gender?: Gender;

  @Type(() => Evaluation)
  evaluations: Evaluation[];

  @Type(() => Financing)
  financings: Financing[];

  @Type(() => Agreement)
  agreement: Agreement[];

  @Type(() => ResponsibleAgreement)
  responsibleAgreement: ResponsibleAgreement[];

  @Type(() => SaleOrder)
  saleOrders: SaleOrder[];

  @Type(() => MaritalStatus)
  maritalStatus?: MaritalStatus;

  @Type(() => Employee)
  employee?: Employee;

  @Type(() => AppraisalExternalOffer)
  appraisalExternalOffers?: AppraisalExternalOffer[];

  @Type(() => AppraisalMarketPrice)
  appraisalMarketPrices?: AppraisalMarketPrice[];

  @Type(() => AutoOwner)
  autos?: AutoOwner[];

  @Type(() => BankData)
  clientAccounts?: BankData[];

  @Type(() => BankData)
  holderIn?: BankData[];

  @Type(() => Concessionaire)
  concessionaires?: Concessionaire[];

  @Type(() => ContactPerson)
  contacts?: ContactPerson[];

  @Type(() => ContactPerson)
  contactsOf?: ContactPerson[];

  @Type(() => ReferencePerson)
  references?: ReferencePerson[];

  @Type(() => ReferencePerson)
  referencesOf?: ReferencePerson[];

  @Type(() => LaborData)
  employeesData?: LaborData[];

  @Type(() => LaborData)
  laborData?: LaborData[];

  @Type(() => Lead)
  leads?: Lead[];

  @Type(() => PersonAccount)
  accounts?: PersonAccount[];

  @Type(() => PersonAddress)
  addresses?: PersonAddress[];

  @Type(() => Email)
  email?: Email;

  @Type(() => Photo)
  photo?: Photo;

  @Type(() => Phone)
  secondaryPhone?: Phone;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  birthDate?: Dayjs;

  uid?: string;

  firstName?: string;
  secondName?: string;
  surname?: string;

  secondSurname?: string;
  companyName?: string;

  alias?: string;
  mainPhone?: string;

  webSite?: string;
  socialNetwork?: object;
  communicationService?: object;

  emergencyContact?: object;

  get phone () {
    const { mainPhone } = this
    return mainPhone?.replaceAll(' ', '')
  }

  get phoneWork () {
    const { secondaryPhone } = this
    return secondaryPhone?.work
  }

  get whatsappMe () {
    const { phone } = this
    if (!phone) return

    return `https://wa.me/${phone}`
  }

  get whatsappMeWork () {
    const { secondaryPhone } = this
    if (!secondaryPhone?.work) return

    return `https://wa.me/${secondaryPhone.work}`
  }

  get message () {
    // TODO: Compute init message for this user.
    const { name } = this
    return `Hola, ${name}!`
  }

  get contact () {
    const { type } = this
    if (!type) return ''

    switch (type.name) {
      case PersonType.company:
        return `${this.companyName} ${this.phone || ''}`.toUpperCase()
      case PersonType.natural:
        return `${this.firstName} ${this.surname} ${this.phone || ''}`.toUpperCase()
      case PersonType.system:
        return this.alias
    }
  }

  get fullName () {
    const { type, companyName, firstName, secondName, surname, secondSurname, alias } = this
    if (!type) return ''

    switch (type.name) {
      case PersonType.company:
        if (!companyName) return ''
        return `${companyName}`.toUpperCase()
      case PersonType.natural:
        return `${firstName} ${secondName || ''} ${surname || ''} ${secondSurname || ''}`.toUpperCase()
      case PersonType.system:
        return alias.toUpperCase()
    }
  }

  get name () {
    const { type } = this
    if (!type) return ''

    switch (type.name) {
      case PersonType.company:
        return `${this.companyName}`.toUpperCase()
      case PersonType.natural:
        return `${this.firstName} ${this.surname || ''}`.toUpperCase()
      default:
        return this.alias.toUpperCase()
    }
  }

  get formInfo () {
    const { name, uid, mainPhone, type, formInfoAlias } = this

    if (type.isCompany) {
      return formInfoAlias
    }

    return `${name} ${uid || ''} ${mainPhone || ''}`.toUpperCase()
  }

  get formInfoAlias () {
    const { alias, uid, mainPhone } = this

    return `${alias || ''} ${uid || ''} ${mainPhone || ''}`.toUpperCase()
  }

  get age () {
    const { birthDate } = this

    return dayjs().diff(birthDate, 'year') || ''
  }

  get shortName () {
    const { firstName, surname, type } = this

    switch (type.name) {
      case PersonType.company:
        return `${this.companyName}`.toUpperCase()
      case PersonType.natural:
        return `${firstName} ${surname}`.toUpperCase()
      default:
        return this.alias.toUpperCase()
    }
  }

  get applicantWithRut () {
    const { shortName, uid } = this

    const name = `${shortName}`
    const rut = uid ? `(${uid})` : ''

    return `${name} ${rut}`
  }

  get birthDateFormatted () {
    const { birthDate } = this
    if (!birthDate) return ''
    return birthDate.format('DD/MM/YYYY')
  }
}
