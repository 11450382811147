import { Entity } from '..'
import { Type } from 'class-transformer'
import { Icon } from '@/entities/settings/Status'

export class AcquisitionType extends Entity {
  @Type(() => Icon)
  icon?: Icon

  name: string;
  color: string;
  description: string;
  active: string;
}
