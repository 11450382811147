import gql from 'graphql-tag'
import { ModelFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${ModelFragment}
  query fetchModel($id: Int!) {
    records: vehicle_model_by_pk(id: $id) {
      ...Model
    }
  }
`

export default query
