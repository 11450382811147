import { Actions } from '../../interfaces'

import find from './find'
import { AcquisitionType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { AcquisitionType },
  actions: { [AcquisitionType.name]: actions },
}
