import { Actions } from '../../interfaces'
import { Model } from '@/entities/vehicle'

import find from './find'
import update from './update'
import create from './create'
import fetch from './fetch'

export const actions: Actions = {
  find,
  update,
  create,
  fetch,
}

export default {
  Model: { Model },
  actions: { [Model.name]: actions },
}
