import gql from 'graphql-tag'

import { ActivityTypeFragment, BaseActivityFragment, LeadFragment } from '@/graphql/fragments/crm'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${LeadFragment}
  ${BaseActivityFragment}
  ${ActivityTypeFragment}
  ${ProcessStatusFragment}
  query findLeadLite($filter: crm_lead_bool_exp) {
    records: crm_lead(where: $filter) {
      id
      executive {
        id
        person {
          id
          firstName: first_name
          surname
        }
      }
      closingReason:closing_reason {
        id
      }
      activities(order_by:{id: desc}){
        ...ActivityBase
        type: activity_type {
          ...ActivityType
        }
        status: process_status {
          ...ProcessStatus
        }
      }
    }
  }
`

export default query
