import gql from 'graphql-tag'
import { PaymentFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentFragment}
  mutation updateBankData($id: Int!, $fields: persons_bank_data_set_input!) {
    record: update_persons_bank_data_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export default mutation
