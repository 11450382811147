import { VueApolloClient } from '@/plugins/apollo'

import { Fetch } from './utils'
import { Cursor, Filter, Query } from '@/entities/public/Resource/interfaces'
import { ResourceData } from '@/store/resources/datatables/state'

import { Lead } from '@/entities/crm'
import leads from './leads'

import { Appraisal, Consignment, Enablement, Inspection, Negotiation, PurchaseOrder } from '@/entities/purchase'
import appraisals from './appraisals'
import { Period } from '@/store/resources/period'
import inspections from './inspections'
import negotiations from '@/graphql/queries/resources/datatables/negotiations'
import purchases from '@/graphql/queries/resources/datatables/purchases'
import { Evaluation, Financing } from '@/entities/loans'
import evaluations from '@/graphql/queries/resources/datatables/evaluations'
import financing from '@/graphql/queries/resources/datatables/financings'
import autos from '@/graphql/queries/resources/datatables/autos'
import { Auto } from '@/entities/public'
import { Reserve, SaleOrder, Stock, StockAdvertiser } from '@/entities/sales'
import sales from '@/graphql/queries/resources/datatables/sales'
import reservations from '@/graphql/queries/resources/datatables/reservations'
import stock from '@/graphql/queries/resources/datatables/stock'
import documents from '@/graphql/queries/resources/datatables/documents'
import { Document } from '@/entities/documents'
import enablement from '@/graphql/queries/resources/datatables/enablement'
import stockAdvertiser from '@/graphql/queries/resources/datatables/stockAdvertiser'
import { ExpenseOrder, Payment } from '@/entities/finance'
import expenses from '@/graphql/queries/resources/datatables/expenses'
import { Employee } from '@/entities/hr'
import employee from '@/graphql/queries/resources/datatables/employee'
import payment from '@/graphql/queries/resources/datatables/payment'
import consignments from '@/graphql/queries/resources/datatables/consignments'

const resources: Record<string, Record<string, Fetch>> = {
  [Lead.name]: leads,
  [Appraisal.name]: appraisals,
  [Inspection.name]: inspections,
  [Negotiation.name]: negotiations,
  [PurchaseOrder.name]: purchases,
  [Financing.name]: financing,
  [Auto.name]: autos,
  [Reserve.name]: reservations,
  [SaleOrder.name]: sales,
  [Stock.name]: stock,
  [StockAdvertiser.name]: stockAdvertiser,
  [Evaluation.name]: evaluations,
  [Document.name]: documents,
  [Enablement.name]: enablement,
  [ExpenseOrder.name]: expenses,
  [Employee.name]: employee,
  [Payment.name]: payment,
  [Consignment.name]: consignments,
}

export async function fetchData (
  client: VueApolloClient,
  query: Query,
  period: Period,
  filter?: Filter,
  cursor?: Cursor,
  force?: boolean
): Promise<ResourceData> {
  if (!query) throw new Error('Query is REQUIRED')
  const { model, queries } = query

  const resource = resources[model]
  if (!resource) console.error(`Unimplemented Model: ${model}`)

  let name = query.name
  if (filter?.query) {
    if (!queries || !queries.find(({ name }) => name === filter.query)) throw new Error(`Unregistered query: ${filter.query} for filter ${filter.name}`)
    name = filter.query
  }

  const fetch = resource[name]
  if (!fetch) throw new Error(`Unknown query: ${name} on Model: ${model}`)

  return fetch(client, query, period, filter, cursor, force)
}
