import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const AcquisitionTypeFragment = gql`
  fragment AcquisitionType on settings_acquisition_type{
    ${EntityFields}
    ${EnumFields}
    icon
    color
    active
  }
`
