import { Actions } from '../../interfaces'
import { PersonAccount } from '@/entities/persons'

import find from './find'
import update from './update'

export const actions: Actions = {
  find,
  update,
}

export default {
  Model: { PersonAccount },
  actions: { [PersonAccount.name]: actions },
}
