import gql from 'graphql-tag'
import { BankDataFragment } from '@/graphql/fragments/persons/bankData'

export const query = gql`
  ${BankDataFragment}
  query fetchBankData($id: Int!) {
    records: persons_bank_data_by_pk(id: $id) {
      ...BankData
      holder{
        id
      }
    }
  }
`
export default query
