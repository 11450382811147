// @ts-nocheck
import { Details, Items, ObjectStructure } from '@/utils/generalInterface'
import { Post } from '@/store/vehicles/vehicle'
import { PropertyType } from '@/entities/audit'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const HEIGHT_CALC = 'calc(100vh) !important'
export const SETTING_COL_LAYOUT = { cols: 12, md: 6, lg: 6 }
export const TEXT_SIZE_H1 = 'text-xl-h1 text-lg-h3 text-md-h3 text-sm-h3'
export const TEXT_SIZE_H2 = 'text-xl-h3 text-lg-h4 text-md-h4 text-sm-h4'
export const TEXT_SIZE_H3 = 'text-xl-h4 text-lg-h5 text-md-h3 text-sm-h2'
export const TEXT_SIZE_H4 =
  'text-xl-body-2 text-lg-subtitle-2 text-md-h6 text-sm-h6'
export const TEXT_SIZE_H5 = 'text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5'

export const GENIO_RELOADING = 'GENIO-reloading'

export const ALLOWED_ROLES = ['admin', 'appraiser']
export const ALLOWED_ROLES_PLATFORM_PUBLISH = ['admin', 'advertiser']
export const FIREBASE_ERROR: Details = {
  'auth/user-not-found': 'user_not_found',
  'auth/wrong-password': 'password_invalid',
  'auth/too-many-requests': 'user_many_request',
  'auth/internal-error': 'password_invalid',
  'auth/network-request-failed': 'network_error',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resizeWidth (vuetify): string {
  if (vuetify.breakpoint.lgOnly) {
    return '550'
  } else if (vuetify.breakpoint.xlOnly) {
    return '500'
  } else if (vuetify.breakpoint.mdOnly) {
    return '300'
  }
  return '200'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function resizeHeight (vuetify): string {
  if (vuetify.breakpoint.lgOnly) {
    return '800'
  } else if (vuetify.breakpoint.xlOnly) {
    return '1000'
  } else if (vuetify.breakpoint.mdOnly) {
    return '802'
  }
  return '300'
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function cast (
  value: string | any,
  type: string,
  metadata?: Record<string, unknown>
): any {
  switch (type) {
    case 'numeric':
      value = Number(value)
      break
    case 'jsonb':
      value = JSON.parse(value)
      value.metadata = metadata
      break
  }

  return value
}

export function fixText (val: string): string {
  if (!val) return ''
  else if (val < 20) return val
  return val.substring(0, 20) + '...'
}

export function objectToString (variable: string): string {
  if (!variable) {
    throw new Error('Error')
  }

  if (typeof variable === 'object') {
    return JSON.stringify(variable)
  }

  return variable.toString()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deepCopy (object: any) {
  return JSON.parse(JSON.stringify(object))
}

export function isDataChanged (
  fields: string[],
  originalData: ObjectStructure,
  data: ObjectStructure
): boolean {
  let isStateDirty = false

  for (const key of fields) {
    const keys: string[] = key.split('.')
    if (Object.prototype.hasOwnProperty.call(data, keys[0])) {
      if (keys.length > 1) {
        if (data[keys[0]][keys[1]] !== originalData[keys[0]][keys[1]]) {
          isStateDirty = true
          break
        }
      } else {
        if (data[key] !== originalData[key]) {
          isStateDirty = true
          break
        }
      }
    }
  }
  return isStateDirty
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function validateImage (image: any, type: string): string {
  return image.thumbnails ? image.thumbnails[type].url : image.url
}

export function url (
  name: string,
  platforms: Array<Items>,
  posts: Array<Post>
): string {
  if (!posts.length) return ''

  const post: Post = posts.find((post: Post) =>
    platforms.some(
      platform => platform.id === post.platformId && platform.name === name
    )
  )

  if (!post || !post.url) return ''

  return post.url
}

export function numberOfPages (total: number, itemsPerPage: number): number {
  if (!total || !itemsPerPage) return 0
  return Math.ceil(total / itemsPerPage)
}

export function offsetPosition (page: number, itemsPerPage: number): number {
  if (!itemsPerPage || !page) return 0
  return itemsPerPage * page - itemsPerPage
}

export const ORDER_MENU = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const ITEM_PER_PAGE_DEFAULT = 5
export const TOTAL_VISIBLE = 8
export const GENERAL = 'General'

export const removeAccents = (text: string): string =>
  text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase()
    .trim()

export function isEmpty (str: string | null | undefined | number | FormDataEntryValue): boolean {
  return !str || str.length === 0
}

export function fieldCast (field): any {
  try {
    return JSON.parse(field)
  } catch {
    return field
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function hasProperty (obj: any, prop: string): boolean {
  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(prop)
}

export const DEFAULT_COLOR = {
  color: '#ffffff',
  backgroundColor: '#6185db',
  hover: '#4964a4',
  focus: '#30426E',
}

export const DEFAULT_ERROR_COLOR = {
  color: '#ffffff',
  backgroundColor: '#ff4949',
  hover: '#B4292A',
  focus: '#781C1C',
}

export function removeValues (text: string): string {
  if (isEmpty(text)) return ''
  return text
    .replace(/\s/g, '')
    .replace(',', '')
    .replace('.', '')
    .replace(';', '')
}

export function MinLongitudeAllowed (
  textLongitude: number,
  max: number
): boolean {
  return textLongitude <= max
}

export function MinMaxLongitudeAllowed (
  textLongitude: number,
  min: number,
  max: number
): boolean {
  return textLongitude > min && textLongitude <= max
}

export function truncateLongText (text: string, max: number): string {
  if (isEmpty(text)) return ''
  if (text.length <= max) return text
  return text.substring(0, max) + '...'
}

export const fixAmountFormatted = (price?: number | string): string => {
  const numericPrice = typeof price === 'string' ? Number(price) : price

  if (!isValidNumber(numericPrice)) return ''

  return `${numericPrice.toLocaleString('de-DE')}`
}

export const fixPrice = (price?: number | string, amount = true, round = true): string => {
  const numericPrice = typeof price === 'string' ? Number(price) : price

  if (!isValidNumber(numericPrice)) return ''

  const val = round ? Math.round(numericPrice) : numericPrice
  if (isNaN(val)) return ''

  if (amount) {
    return `$${fixAmountFormatted(val)}`
  }

  return fixAmountFormatted(val)
}

export const fixThousands = (value: number | string) => {
  const numericValue = typeof value === 'string' ? Number(value) : value
  if (!isValidNumber(numericValue)) return ''
  const strValue = String(numericValue)
  const splitResult = []
  let newElement = ''
  for (let i = strValue.length - 1; i >= 0; i -= 1) {
    if (newElement.length < 3) {
      newElement = strValue[i] + newElement
    }
    if (newElement.length === 3 || i === 0) {
      splitResult.unshift(newElement)
      newElement = ''
    }
  }
  return splitResult.join('.')
}

export const timeAgo = (date: string): string => {
  const admissionDate = new Date(date)
  const monthDiff = Date.now() - admissionDate.getTime()
  const ageDt = new Date(monthDiff)
  const year = ageDt.getUTCFullYear()
  const months = ageDt.getMonth()
  const years = Math.abs(year - 1970)
  const yearMsg = years > 0 ? (years > 1 ? ' Años' : ' Año') : ''
  const monthMsg = months > 0 ? (months > 1 ? ' Meses' : ' Mes') : ' Mes'
  const comma = years && months ? ', ' : ''
  return `${
    years || ''
  }${yearMsg}${comma}${months}${monthMsg}`
}

export const fixDate = (date: string | null | undefined): string => {
  if (date && date.includes('/')) {
    const dateSplitted = date.split('/') || ''

    return `${dateSplitted[1]}/${dateSplitted[0]}/${dateSplitted[2]}`
  }
  return date
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function toCamelCase (str: string): string {
  return str.replace(/(_\w)/g, match => match.charAt(1).toUpperCase())
}

function isObject (value: any): boolean {
  return value && typeof value === 'object' && !Array.isArray(value)
}

export function convertObjectPropertiesToCamelCase (obj: Record<string, any> | null | undefined): Record<string, any> | null | undefined {
  if (obj === null || obj === undefined) {
    return obj
  }

  const newObj: Record<string, any> = {}

  Object.keys(obj).forEach(key => {
    const newKey = toCamelCase(key)

    if (isObject(obj[key])) {
      newObj[newKey] = convertObjectPropertiesToCamelCase(obj[key])
    } else {
      if (obj[key]) {
        newObj[newKey] = obj[key]
      }
    }
  })

  return newObj
}

export function typeValue (val) {
  let type: PropertyType = 'text'
  if (typeof value === 'number') {
    type = 'number'
  } else if (/([+(\d])(([\d+() -.]){5,16})([+(\d])/gm.test(val)) {
    type = 'phone'
  } else if (/^http(|s):.*\.(jpg|png|jpeg|gif)/g.test(val)) {
    type = 'image'
  } else if (/^http(s)?:\/\//.test(val)) {
    type = 'link'
  } else if (/^([\w.\-_]+)?\w+@[\w-_]+(\.\w+)+$/igm.test(val)) {
    type = 'email'
  }
  return type
}

export function intervalFormat (start, end) {
  const endDate = dayjs(end)
  const startDate = dayjs(start)
  const differenceInMilliseconds = endDate.diff(startDate)
  const dayjsDuration = dayjs.duration(differenceInMilliseconds)

  return `P${dayjsDuration.days()}DT${dayjsDuration.hours()}H${dayjsDuration.minutes()}M${dayjsDuration.seconds()}S`
}

export function sendNotification (message: string, type: string) {
  window.dispatchEvent(
    new CustomEvent('notification-message', {
      detail: {
        type,
        message,
      },
    })
  )
}

export function updateNestedObject (obj, searchKey, newValue, excludeKeys = []) {
  const newObj = deepCopy(obj)
  recursiveUpdate(newObj, searchKey, newValue, excludeKeys)
  return newObj
}

export function handleArray (obj: Record<string, any>, key: string, newValue: any[]): void {
  const newEntry = newValue.find(entry => entry.target === obj[key])
  if (newEntry) {
    obj[key] = newEntry.value
  }
}

export function handleObject (obj: Record<string, any>, key: string, newValue: Record<string, any>): void {
  if (newValue.hasOwnProperty(obj[key])) {
    obj[key] = newValue[obj[key]]
  }
}

export function shouldUpdateKey (key, excludeKeys) {
  return !excludeKeys.includes(key)
}

export function updateValue (obj, key, newValue) {
  if (Array.isArray(newValue)) {
    handleArray(obj, key, newValue)
  } else if (typeof newValue === 'object') {
    handleObject(obj, key, newValue)
  } else {
    obj[key] = newValue
  }
}

export function updateStringEndingWithAt (obj, key, newValue) {
  const value = obj[key]
  if (typeof value === 'string' && value.endsWith('@')) {
    obj[key] = `%${newValue}%`
  }
}

// @ts-ignore
export const initArray = (size: number, value: any) => Array.apply(null, Array(size)).map(() => value)

export function recursiveUpdate (obj, searchKey, newValue, excludeKeys) {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (!shouldUpdateKey(key, excludeKeys)) continue

      if (key === searchKey) {
        updateValue(obj, key, newValue)
      } else {
        updateStringEndingWithAt(obj, key, newValue)
        recursiveUpdate(obj[key], searchKey, newValue, excludeKeys)
      }
    }
  }
}

export const genericViews = {
  Lead: 'generic-lead',
  Evaluation: 'generic-evaluation',
  Purchase: 'generic-purchase-edit',
  Inspection: 'generic-inspection-inspector',
  InspectionSupervisor: 'generic-inspection-supervisor',
  Negotiation: 'generic-negotiation',
  NegotiationSupervisor: 'generic-negotiation-supervisor',
  PurchaseOrder: 'generic-purchase',
  Quota: 'generic-quota',
  Appraisal: 'generic-appraisal-edit',
  Appraiser: 'generic-appraisal-appraiser-edit',
  AppraisalSupervisor: 'generic-appraisal-supervisor-edit',
  LeadForwarder: 'generic-lead-forwarder',
  LeadSupervisor: 'generic-lead-supervisor',
  Reserve: 'generic-reserve-edit',
  EvaluationClosing: 'generic-close-evaluation',
  ValidatorSaleOrder: 'generic-validator-sale-order',
  SaleOrder: 'generic-sale-order',
  ValidatorTransfer: 'generic-document-transfer',
  ValidatorReserve: 'generic-validator-reserve',
  ValidatorPurchaseOrder: 'generic-validator-purchase-order',
  Enablement: 'generic-enablement',
  ValidatorEnablement: 'generic-validator-enablement',
  SupervisorEnablement: 'generic-supervisor-enablement',
  StockPrice: 'generic-stock-price',
  PublishStock: 'generic-publish-stock',
  Stock: 'generic-stock-details',
  ExpenseOrder: 'generic-treasurer-expense',
  Employee: 'generic-sysop-employee',
  SupervisorStockPublish: 'supervisor-stock',
  TreasurerIncome: 'generic-treasurer-income',
}

export function getFormView (model, view, generic) {
  const isViewGeneric = view?.includes('generic')
  if (isViewGeneric) {
    return view
  }
  const genericForm = genericViews[generic] ?? genericViews[model]
  return genericForm ?? view
}

export function dateToLocal (date) {
  if (!date) return null

  const local = dayjs(date)

  const offset = dayjs().utcOffset()

  const localSchedulingDate = local.add(offset, 'minute')

  return dayjs(localSchedulingDate)
}

export function parseToNumber (value) {
  if (value === undefined || value === null) {
    return null
  }

  const number = Number(value)

  return isNaN(number) ? null : number
}

export function isBeforeToday (date) {
  if (!date?.isValid()) return
  const today = dayjs()
  const dateToCheck = date.endOf('day')
  return dateToLocal(dateToCheck).isBefore(today)
}

export function stringifySafe (objects: any[]) {
  const cache = new WeakSet()
  const replacer = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        return
      }
      cache.add(value)
    }
    return value
  }

  return objects.map(obj => JSON.stringify(obj, replacer)).join('')
}

export function replaceText (text, value, number = false, symbol = '$') {
  if (!text?.length) return ''

  return text.replace(symbol, number ? fixPrice(value, false) : value || '')
}

export function getProgressColor (progress) {
  if (!progress) return 'red'

  switch (true) {
    case (progress >= 0 && progress < 25):
      return 'red'
    case (progress >= 25 && progress < 50):
      return 'orange'
    case (progress >= 50 && progress < 75):
      return 'yellow'
    case (progress >= 75 && progress < 100):
      return 'green'
    default:
      return 'primary'
  }
}

export function minLength (min: number, lastWord = 'caracteres') {
  return (v: string): any => !v || v.length >= min || `Debe tener al menos ${min} ${lastWord}`
}

export function maxLength (max: number, lastWord = 'caracteres') {
  return (v: string): any => !v || v.length <= max || `Debe tener como máximo ${max} ${lastWord}`
}

export function isValidNumber (num: number | string | null | undefined): boolean {
  if (typeof num === 'string' && num !== '') {
    return Number.isFinite(Number(num))
  }

  return Number.isFinite(num)
}

export function paymentIcon (status: string) {
  if (!status) return ''

  return status === 'paid' ? 'mdi-eye' : 'mdi-pencil-outline'
}
