import gql from 'graphql-tag'

export const mutation = gql`
  mutation updatePersonAccount($id: Int!, $fields: persons_person_account_set_input!) {
    record: update_persons_person_account_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export default mutation
