import gql from 'graphql-tag'

export const mutation = gql`
  mutation insertFinancialAccount($fields: finance_financial_account_insert_input!) {
    record: insert_finance_financial_account_one(object: $fields) {
      id
    }
  }
`
export default mutation
