import { Actions } from '../../interfaces'

import { FinancialAccount } from '@/entities/finance'

import find from './find'
import create from './create'
import remove from './remove'
import update from './update'

export const actions: Actions = {
  find,
  create,
  remove,
  update,
}

export default {
  Model: { FinancialAccount },
  actions: { [FinancialAccount.name]: actions },
}
