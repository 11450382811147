export interface PersonInformation {
  id: number
  rut: string
  birthDate?: null | string
  birthDateDisplay?: null | string
  names: Names
  age?: null | number
  alias?: null | string
  companyName?: null | string
  idNationality?: null | number
  idMaritalStatus?: null | string
  idGender?: null | number
  personalEmail?: null | string
  idPersonType?: null | string
  workEmail?: null
  nationality?: null | string
  gender?: null | string
  personalPhone: string
  maritalStatus?: null | string
  workPhone?: null | string
  personalBankData?: undefined | bankDataFull[]
  personalLaborData?: undefined | laborData[]
  personalAddressData?: undefined | Address[]
  personalReferenceData?: undefined | personalReferenceData[]
}

export interface Address {
  idAddress?: number
  streetName: string
  streetNumber: string
  idCity?: number
  idPersonAddress?: number
  cityName: string
  idRegion?: number
  regionAbbreviation: string
  idAddressType?: string
  addressType: string
}

export interface bankDataFull {
  accountNumber: string
  accountType: string
  bank: string
  idAccountType?: string
  idBank?: number
  idBankData?: number
  idPersonAccount?: number
}

export interface laborData {
  workerType: string
  incomeType: string
  rent: string
  jobTitle: string
  admissionDate: string
  idLaborData: number
  idTypeWorker: string
  idIncomeType: string
  timeSinceAdmission: string
}

export interface personalReferenceData {
  fullname: string
  generalRelation: string
  phone: string
  idPersonalReference?: number
  idPersonReference?: number
  idGeneralRelation?: string
}

export interface Names {
  rut: string
  firstName: string
  secondName: string
  firstLastName: string
  secondLastName: string
  otherNames?: null
}

export class PersonFormatted {
  public details: PersonInformation;

  constructor (details: PersonInformation) {
    this.details = details
  }

  get id (): number | null {
    if (!this.details || !this.details.id) return null
    return this.details.id
  }

  get name (): string {
    if (!this.details || !this.details.names || !this.details.names.firstName) {
      return ''
    }
    return this.details.names.firstName
  }

  get secondName (): string {
    if (!this.details || !this.details.names || !this.details.names.secondName) {
      return ''
    }
    return this.details.names.secondName
  }

  get surname (): string {
    if (
      !this.details ||
      !this.details.names ||
      !this.details.names.firstLastName
    ) {
      return ''
    }
    return this.details.names.firstLastName
  }

  get secondSurname (): string {
    if (
      !this.details ||
      !this.details.names ||
      !this.details.names.secondLastName
    ) {
      return ''
    }
    return this.details.names.secondLastName
  }

  get rut (): string {
    if (
      (!this.details || !this.details.names || !this.details.names.rut) &&
      !this.details.rut
    ) {
      return ''
    }
    return this.details.rut || this.details.names.rut
  }

  get phone (): string {
    if (!this.details || !this.details.personalPhone) return ''
    return this.details.personalPhone.replaceAll(' ', '')
  }

  get phoneWork (): string {
    if (!this.details || !this.details.workPhone) return ''
    return this.details.workPhone
  }

  get email (): string {
    if (!this.details || !this.details.personalEmail) return ''
    return this.details.personalEmail
  }

  get emailWork (): string {
    if (!this.details || !this.details.workEmail) return ''
    return this.details.workEmail
  }

  get birthDate (): string {
    if (!this.details || !this.details.birthDate) return ''

    const dateSplitted = this.details.birthDate.split('/') || ''
    return `${parseInt(dateSplitted[0]) < 10 ? 0 + dateSplitted[0] : dateSplitted[0]}/${parseInt(dateSplitted[1]) < 10 ? 0 + dateSplitted[1] : dateSplitted[1]}/${dateSplitted[2]}`
  }

  get age (): number | null {
    if (!this.details || !this.details.age) return null
    return this.details.age
  }

  get type (): string {
    return this.details?.idPersonType
  }

  get maritalStatus (): string {
    if (!this.details || !this.details.idMaritalStatus) return ''
    return this.details.idMaritalStatus
  }

  get gender (): string {
    if (!this.details || !this.details.idGender) return ''
    return this.details.idGender.toString()
  }

  get genderName (): string {
    if (!this.details || !this.details.gender) return ''
    return this.details.gender
  }

  get nationality (): number {
    if (!this.details || !this.details.idNationality) return null
    return this.details.idNationality
  }

  get nationalityName (): string {
    if (!this.details || !this.details.nationality) return ''
    return this.details.nationality
  }

  get bankData (): Array<bankDataFull> | undefined {
    if (!this.details || !this.details.personalBankData) return []

    return this.details.personalBankData
  }

  get directionData (): Array<Address> | undefined {
    if (!this.details || !this.details.personalAddressData) return []

    return this.details.personalAddressData
  }

  get references (): Array<personalReferenceData> | undefined {
    if (!this.details || !this.details.personalReferenceData) return []

    return this.details.personalReferenceData
  }

  get laborData (): Array<laborData> | undefined {
    if (!this.details || !this.details.personalLaborData || !this.details.personalLaborData.length) return []

    return this.details.personalLaborData
  }

  get personType (): string {
    if (!this.details || !this.details.idPersonType) return ''
    return this.details.idPersonType
  }

  get companyName (): string {
    if (!this.details || !this.details.companyName) return ''
    return this.details.companyName
  }

  get alias (): string {
    if (!this.details || !this.details.alias) return ''
    return this.details.alias
  }
}
