import { VueApolloClient } from '@/plugins/apollo'
import { DocumentNode } from 'graphql'
import { plainToInstance } from 'class-transformer'
import dayjs from 'dayjs'

import { Cursor, Filter, Model, Query, ResourceType } from '@/entities/public/Resource/interfaces'
import { ResourceData } from '@/store/resources/datatables/state'

import { Lead } from '@/entities/crm'
import { Period } from '@/store/resources/period'
import { Appraisal, Consignment, Enablement, Inspection, Negotiation, PurchaseOrder } from '@/entities/purchase'
import { Evaluation, Financing } from '@/entities/loans'
import { Reserve, SaleOrder, Stock, StockAdvertiser } from '@/entities/sales'
import { Auto } from '@/entities/public'
import { Document } from '@/entities/documents'
import { ExpenseOrder, Payment } from '@/entities/finance'
import { Employee } from '@/entities/hr'

const models: Record<string, Model> = {
  Lead,
  Appraisal,
  Inspection,
  Negotiation,
  PurchaseOrder,
  Financing,
  Auto,
  Reserve,
  SaleOrder,
  Stock,
  Evaluation,
  Document,
  Enablement,
  StockAdvertiser,
  ExpenseOrder,
  Employee,
  Payment,
  Consignment,
}

function parse (records: Array<any>, Model: Model): Array<ResourceType> {
  if (!records) throw new Error('Records are required')
  return plainToInstance(Model, records as Object[]) as Array<ResourceType>
}

export type Fetch = (client: VueApolloClient,
                     query: Query,
                     period: Period,
                     filter: Filter,
                     cursor: Cursor,
                     force?: boolean) => Promise<ResourceData>

type AnyObject = { [key: string]: any };

export function transformInterval (obj: AnyObject): AnyObject {
  if (!obj) return
  let modified = false
  const result = JSON.parse(JSON.stringify(obj))

  function checkAndReplace (obj: AnyObject): void {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        checkAndReplace(obj[key])
      } else if (key === '_lt' || key === '_gte') {
        const intervalMatch = obj[key].match(/genio_interval '(\d+) (days|hours|months|years)'/)
        const actionMatch = obj[key].match(/genio_interval '(\d+) (days|hours|months|years) (add|subtract)'/)
        if (intervalMatch || actionMatch) {
          modified = true
          const value = parseInt(intervalMatch ? intervalMatch[1] : actionMatch[1], 10)
          const unit = intervalMatch ? intervalMatch[2] : actionMatch[2]
          const action = actionMatch ? actionMatch[3] : 'subtract'

          let date

          if (action === 'subtract') {
            date = dayjs().subtract(value, unit)
          } else {
            date = dayjs().add(value, unit)
          }

          obj[key] = date.toISOString()
        }
      }
    }
  }

  checkAndReplace(result)

  return modified ? result : obj
}

export function QueryBuilder (query: DocumentNode, model: Model): Fetch {
  const Model = models[model.name]
  if (!model || !Model) throw new Error(`No matching model found of type "${model.name}"`)

  return async function fetch (client, definition, period, filter, cursor, force): Promise<ResourceData> {
    const { where: find } = filter
    const { order, params } = definition
    const { page, size, refresh } = cursor
    const where = transformInterval(find)

    const fetchPolicy = force || refresh ? 'network-only' : 'cache-first'
    const variables = {
      filter: { _and: [period.where, where].filter(_ => _) },
      order: filter.order || order,
      limit: size,
      offset: (page - 1) * size,
      ...params,
    }

    const {
      data: {
        records,
        total: { aggregate: { count } },
      },
    } = await client.query({ query, variables, fetchPolicy })
    const data = parse(records, Model)
    if (refresh) cursor.refresh = false

    return {
      total: count,
      records: data,
    }
  }
}
