import { Type } from 'class-transformer'
import { Entity } from '..'
import { PersonAccount } from '@/entities/persons'
import { Payment } from '@/entities/finance/Payment'
import { FinancialCategory } from '.'

export class FinancialAccount extends Entity {
  @Type(() => FinancialCategory)
  category: FinancialCategory

  @Type(() => PersonAccount)
  personAccount: PersonAccount

  @Type(() => Payment)
  payments: Payment[]

  idPersonAccount: number
  balance: number
  description: string

  get bankAccount (): string {
    return this.personAccount?.name
  }
}
