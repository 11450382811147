import axios from 'axios'

const { VUE_APP_BASE_URL } = process.env

export async function sendError (errorInfo) {
  await axios.post(`${VUE_APP_BASE_URL}/frontend-notification/api/v1/frontend-notification-service`,
    errorInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
}
