import { Actions } from '../../interfaces'
import { BankData } from '@/entities/persons'

import find from './find'
import update from './update'
import fetch from './fetch'

export const actions: Actions = {
  find,
  update,
  fetch,
}

export default {
  Model: { BankData },
  actions: { [BankData.name]: actions },
}
