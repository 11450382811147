import gql from 'graphql-tag'

import { PurchaseOrderFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
    ${PurchaseOrderFragment}
    mutation updateConsignment($id: Int!, $fields: purchase_purchase_order_set_input!) {
        record: update_purchase_purchase_order_by_pk(pk_columns: {id: $id}, _set: $fields) {
            ...PurchaseOrder
        }
    }
`

export default mutation
