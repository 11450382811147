import gql from 'graphql-tag'
import { AcquisitionTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${AcquisitionTypeFragment}
  query findAcquisitionType($filter: settings_acquisition_type_bool_exp, $order: [settings_acquisition_type_order_by!], $limit: Int){
    records: settings_acquisition_type(where: $filter, order_by: $order, limit: $limit) {
      ...AcquisitionType
    }
  }
`

export default query
