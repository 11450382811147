import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '..'

import { Channel, Deal, LeadActivity, Pipeline } from '.'
import { Person } from '../persons'
import { ClosingReason, ProcessStatus } from '../settings'
import { Employee } from '../hr'
import { LeadActivityIcons, LeadActivityStatus, LeadStatus, LeadStatusIcons } from '@/models/lead/interfaces'
import { AppraisalStatusAlert, AppraisalStatusAlertIcons, Icon, StockStatus, StockStatusIcons } from '@/models/interfaces'
import { Auto } from '../public/Auto'
import { dateToLocal, toCamelCase } from '@/utils/general'
import dayjs from 'dayjs'

class LeadMetadata {
  note: string
}

export class Lead extends Entity {
  @Type(() => Person)
  client: Person;

  @Type(() => Pipeline)
  pipeline: Pipeline;

  @Type(() => Deal)
  deals: Deal[];

  @Type(() => Channel)
  initialChannel: Channel;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => Employee)
  executive: Employee;

  @Type(() => LeadActivity)
  activities: LeadActivity[];

  @Type(() => Employee)
  forwarder: Employee;

  @Type(() => ClosingReason)
  closingReason: ClosingReason;

  @Type(() => LeadMetadata)
  metadata: LeadMetadata;

  declare _sale?: Deal
  declare _purchase?: Deal

  private addDeal (value) {
    const { deals } = this
    const deal = value instanceof Deal ? value : plainToInstance(Deal, value)

    if (!deals) this.deals = [deal]
    else {
      const { channel, intention, initialNote } = this
      deal.channel = channel
      deal.intention = intention
      deal.initialNote = initialNote
      deals.push(deal)
    }
  }

  get sale (): Deal | undefined {
    const { _sale } = this
    if (_sale) return _sale

    const sale = this.deals?.filter(({ isSale }) => isSale)
    const value = sale?.[sale.length - 1]
    if (value) Object.defineProperty(this, '_sale', { value })
    return value
  }

  set sale (value) {
    const { sale } = this
    if (!sale) this.addDeal(value)
  }

  get saleStatus () {
    const { sale } = this
    if (!sale) return

    return {
      status: sale.stock.status,
      price: sale.auto.price,
    } as Auto
  }

  get purchase (): Deal | undefined {
    const { _purchase } = this
    if (_purchase) return _purchase

    const deal = this.deals?.filter(({ isPurchase }) => isPurchase)

    const value = deal?.[deal.length - 1]

    if (value) Object.defineProperty(this, '_purchase', { value })
    return value
  }

  set purchase (value) {
    const { purchase } = this
    if (!purchase) this.addDeal(value)
  }

  get alerts (): Array<any> {
    return [
      this.intentionAlert,
      this.closedAlert,
      this.activityAlert,
      this.stockAlert,
      this.appraisalAlert,
      this.stockPriceAlert,
      this.financialAlert,
      this.inspectionAlert,
      this.negotiationAlert,
    ].filter(_ => _)
  }

  get intentionAlert () {
    const { sale, purchase } = this
    const intention = (!sale?.closingReason && sale?.intention) || (!purchase?.closingReason && purchase?.intention)

    return intention?.name === 'wants_formalize' ? {
      tooltip: intention.description,
      name: intention.description,
      icon: 'mdi-fire',
      color: 'red',
      background: 'white',
    } : null
  }

  get closedAlert () {
    const { isClosed } = this
    return isClosed && LeadStatusIcons[LeadStatus.close]
  }

  get stockPriceTaskAlert () {
    const { sale, activities } = this

    if (!sale) {
      return
    }

    if (sale?.stock?.prices?.[0]?.createdAt && activities?.length) {
      const saleCreated = sale?.stock?.prices?.[0]?.createdAt
      const task = activities?.[0]?.createdAt

      if (task?.diff(saleCreated) < 0) {
        return LeadActivityIcons[LeadActivityStatus.delayed]
      }
    }
  }

  get activityAlert (): Icon {
    const { activities, purchase, stockPriceTaskAlert } = this

    if (stockPriceTaskAlert) {
      return stockPriceTaskAlert
    }

    let isDelayed = false
    if (purchase) {
      const { appraisal: { status: { isNotOffer, isPending } } } = purchase

      isDelayed = isNotOffer || isPending
    }

    const activitiesStatus = activities.filter(activity => !activity.status.isClosed).map(({ activityStatus }) => activityStatus)
    const isAllTaskFinished = activitiesStatus.every(activity => !activity)

    if (!activities?.length && !isDelayed) {
      return LeadActivityIcons[LeadActivityStatus.noManagement]
    }
    if ((!activities?.length || isAllTaskFinished) && isDelayed) {
      return LeadActivityIcons[LeadActivityStatus.noManagement]
    }

    if (isAllTaskFinished) {
      return LeadActivityIcons[LeadActivityStatus.ok]
    }
    if (activitiesStatus.includes(LeadActivityStatus.delayed)) {
      return LeadActivityIcons[LeadActivityStatus.delayed]
    }
    if (activitiesStatus.includes(LeadActivityStatus.toExpired)) {
      return LeadActivityIcons[LeadActivityStatus.toExpired]
    }

    return LeadActivityIcons[LeadActivityStatus.ok]
  }

  get inspectionAlert () {
    const { purchase } = this

    if (!purchase?.appraisal) return undefined

    const { inspection } = purchase.appraisal

    if (!inspection) return undefined

    const { alert } = inspection

    return alert
  }

  get negotiationAlert () {
    const { purchase } = this

    if (!purchase?.appraisal) return undefined

    const { inspection } = purchase.appraisal

    if (!inspection) return undefined

    const { negotiation } = inspection

    if (!negotiation) return undefined

    const { alert } = negotiation

    return alert
  }

  get isStockSold () {
    const { sale } = this
    if (!sale) return false

    return sale.stock.status.isSold && sale.order.status.isPending && !sale.closingReason
  }

  get isStockReserved () {
    const { sale } = this
    if (!sale) return false
    const vehicleStatusReserved = (sale?.stock.status.isReserved || sale?.stock.status.isReservationProcess)

    return vehicleStatusReserved && !sale.order.reserve && !sale.closingReason
  }

  get stockAlert (): Icon {
    const { sale, isStockSold, isStockReserved } = this
    if (!sale?.stock || (!isStockSold && !isStockReserved)) return undefined

    const status = sale.stock.status.name
    if (isStockSold && status === 'sold') {
      return StockStatusIcons[StockStatus[toCamelCase(status)]]
    }

    if (isStockReserved && (status === 'reserved' || status === 'reservation_process')) {
      return StockStatusIcons[StockStatus[toCamelCase(status)]]
    }

    return StockStatusIcons[StockStatus[status]]
  }

  get appraisalAlert (): Icon {
    const { purchase } = this
    if (!purchase?.appraisal) return undefined

    const { alert, agreement: { response } } = purchase.appraisal
    if (!response) return undefined

    const { createdAt } = response
    if (AppraisalStatusAlert.appraised === alert) {
      const { activities } = this
      const isOk = activities.some(activity => activity.createdAt.diff(createdAt) > 0)
      if (isOk) return undefined
    }

    return AppraisalStatusAlertIcons[alert]
  }

  get stockPriceAlert (): any {
    const { sale, closingReason, stockPriceTaskAlert } = this

    if (!sale || sale?.stock?.prices?.length < 2 || closingReason?.id || !stockPriceTaskAlert) return undefined

    const saleDate = sale.stock.prices[0].createdAt
    const currentDate = dayjs()

    if (currentDate.diff(saleDate, 'day') > 5) return undefined

    if (sale.stock.prices[0].amount > sale.stock.prices[1].amount) {
      return { background: 'red', icon: 'mdi-currency-usd', color: undefined, tooltip: 'Subida de precio' }
    }

    return { background: 'green', icon: 'mdi-currency-usd', color: undefined, tooltip: 'Baja de precio' }
  }

  get financialAlert (): any {
    const { sale } = this

    if (!sale?.financial?.evaluation?.status) return undefined

    const status = sale.financial.evaluation.status
    const closingReason = sale.financial.evaluation?.evaluation?.closingReason?.title

    const tooltip = closingReason ? `Financiamiento ${closingReason}` : `Financiamiento ${status.description}`

    return {
      background: status.color,
      icon: status.icon,
      color: undefined,
      tooltip,
    }
  }

  get channel () {
    const { initialChannel } = this
    return initialChannel
  }

  set channel (value) {
    const { deals } = this
    deals.forEach(deal => deal.channel = value)
  }

  get intention () {
    const { deals, channel } = this
    return deals && deals[0]?.intention
  }

  set intention (value) {
    const { deals } = this
    deals.forEach(deal => deal.intention = value)
  }

  get initialNote () {
    const { deals, metadata } = this

    const deal = deals && deals.find(({ auto, initialNote }) => auto && initialNote)
    return metadata?.note || deal?.initialNote
  }

  set initialNote (value) {
    const { deals } = this
    deals.forEach(deal => deal.initialNote = value)
  }

  get activityScheduled () {
    const { activities } = this
    if (!activities?.length) return

    const { status, schedulingDate } = activities[0]

    return status.isFinished ? '' : dateToLocal(schedulingDate).format('DD/MM/YY HH:mm:ss')
  }

  get computedPipeline () {
    const { purchase, sale } = this

    const isPurchase = Boolean(purchase?.auto)
    const isSale = Boolean(sale?.auto)
    if (!isPurchase && !isSale) return 6

    if (isSale) {
      const isSaleFinancing = sale.saleOrders?.some(order => order.isFinanced && !order.isClosed)

      return isSaleFinancing
        ? isPurchase ? 5 : 3
        : isPurchase ? 4 : 2
    }

    return 1
  }

  get appraisalButton () {
    const { computedPipeline, purchase } = this

    return {
      disabled: ![5, 4, 1].includes(computedPipeline),
      contact: purchase?.appraisal?.appraiser,
    }
  }

  get buttonFI () {
    const { computedPipeline, sale } = this
    return {
      disabled: ![5, 3].includes(computedPipeline),
      contact: sale?.financial?.responsible,
    }
  }

  get buttonReserve () {
    const { computedPipeline, id, isMyReserve, vehicleUnavailable, sale, vehicleSellingProcess, isClosed } = this
    const isDisabled = (![5, 4, 2, 3].includes(computedPipeline) || vehicleUnavailable) && !isMyReserve
    return {
      disabled: isDisabled || isClosed || vehicleSellingProcess,
      parent: { id, model: 'Lead' },
      id: sale?.saleOrders[0]?.reserve?.id,
      constructor: { name: 'Reserve' },
    }
  }

  get buttonSell () {
    const {
      computedPipeline,
      id,
      isMyReserve,
      vehicleUnavailable,
      isMySell,
      isClosed,
      sale,
      vehicleIsSold,
      vehicleWithoutPrice,
    } = this
    const isDisabled = (![5, 4, 2, 3].includes(computedPipeline) || vehicleUnavailable) && !isMyReserve && !isMySell
    return {
      disabled: isDisabled || isClosed || vehicleIsSold || vehicleWithoutPrice,
      parent: { id, model: 'Lead' },
      id: sale?.saleOrders?.[0]?.id,
      constructor: { name: 'SaleOrder' },
    }
  }

  get buttonInspection () {
    const { computedPipeline, purchase, id, isClosed } = this
    const inspection = ![5, 4, 1].includes(computedPipeline)
    const isInspection = Boolean(purchase?.appraisal?.inspection?.date) || Boolean(purchase?.appraisal?.inspection?.status?.isClosed)
    const isDisabled = inspection || isClosed || purchase?.appraisal?.disabledInspection || isInspection
    const isStatus = purchase?.appraisal?.status?.isValidCav || purchase?.appraisal?.status?.isApproved

    return {
      disabled: isDisabled || !(isStatus && purchase?.appraisal?.agreedAmount),
      parent: { id, model: 'Lead' },
      constructor: { name: 'Inspection' },
      id: purchase?.appraisal?.inspection?.id,
    }
  }

  get activity () {
    const { activities } = this

    return (activities && activities[0]) || new LeadActivity(this)
  }

  get buttonActivity () {
    const { activities, isClosed, id, deals } = this

    return {
      disabled: isClosed || !deals.length,
      parent: { id, model: 'Lead' },
      constructor: { name: 'LeadActivity' },
      id: activities.length ? activities[0].id : null,
    }
  }

  get lastLeadActivityOutDate () {
    const { activities } = this

    if (activities.length === 0) return ''

    return activities[0].schedulingDateLocalTime.format('DD/MM/YY HH:mm')
  }

  get isMyReserve () {
    const { sale } = this

    if (!sale) return false

    return Boolean(sale?.saleOrders[0]?.reserve?.id)
  }

  get isMySell () {
    const { sale } = this

    if (!sale) return false

    return Boolean(sale.saleOrders.some(order => order.status.isActive))
  }

  get vehicleUnavailable () {
    const { sale } = this
    if (!sale?.stock) return false

    return Boolean(sale.stock.status.isReserved) || Boolean(sale.stock.status.isReservationProcess) || Boolean(sale.stock.status.isSelling)
  }

  get vehicleWithoutPrice () {
    const { sale } = this

    if (!sale?.stock) return true

    return !sale.stock?.prices?.length
  }

  get vehicleSellingProcess () {
    const { sale } = this

    if (!sale?.stock) return false

    return (Boolean(sale.stock.status.isSelling)) || (Boolean(sale.stock.status.isSold))
  }

  get vehicleIsSold () {
    const { sale } = this

    if (!sale?.stock) return false

    return Boolean(sale.stock.status.isSold)
  }

  get isClosed () {
    const { closingReason } = this

    return Boolean(closingReason)
  }
}
