import gql from 'graphql-tag'
import { EntityFields } from '..'
import { EmployeeFragment } from '../hr'
import {
  ClosingReasonFragment,
  ProcessStatusFragment,
  SupportDocumentTypeFragment,
  TransferTypeFragment,
} from '../settings'

import { AcquisitionTypeFragment } from '../settings/acquisitionType'

export const BasePurchaseOrderFragment = gql`
  ${AcquisitionTypeFragment}
  fragment BasePurchaseOrder on purchase_purchase_order {
    ${EntityFields}
    agreedAmount:agreed_amount
    acquisitionType: acquisition_type {
      ...AcquisitionType
    }
  }
`

export const PurchaseOrderFragment = gql`
  ${BasePurchaseOrderFragment}
  ${EmployeeFragment}
  ${ProcessStatusFragment}
  ${ClosingReasonFragment}
  ${TransferTypeFragment}
  ${SupportDocumentTypeFragment}
  fragment PurchaseOrder on purchase_purchase_order {
    ...BasePurchaseOrder
    validator {
      ...Employee
    }
    status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    transferType: transfer_type {
      ...TransferType
    }
    supportDocumentType: support_document_type {
      ...SupportDocumentType
    }
  }
`
