import { Actions } from '../interfaces'

import appraisal from './appraisal'
import appraisalResponse from './appraisalResponse'
import appraisalResponseType from './appraisalResponseType'
import appraisalExternalOffer from './appraisalExternalOffer'
import inspection from './inspection'
import negotiation from './negotiation'
import negotiationResponse from './negotiationResponse'
import purchaseOrder from './purchaseOrder'
import inspectionQualification from './inspectionQualification'
import inspectionInspectedComponent from './inspectionInspectedComponent'
import processTrafficTicket from './processTrafficTicket'
import enablement from './enablement'
import inspectionComponent from './inspectionComponent'
import inspectionView from './inspectionView'
import appraisalDetail from './appraisalDetail'
import consignment from './consignment'

export const Models = {
  ...appraisal.Model,
  ...appraisalResponse.Model,
  ...appraisalResponseType.Model,
  ...appraisalExternalOffer.Model,
  ...inspection.Model,
  ...negotiation.Model,
  ...negotiationResponse.Model,
  ...purchaseOrder.Model,
  ...inspectionQualification.Model,
  ...inspectionInspectedComponent.Model,
  ...processTrafficTicket.Model,
  ...enablement.Model,
  ...inspectionComponent.Model,
  ...inspectionView.Model,
  ...appraisalDetail.Model,
  ...consignment.Model,
}

export const actions: Record<string, Actions> = {
  ...appraisal.actions,
  ...appraisalResponse.actions,
  ...appraisalResponseType.actions,
  ...appraisalExternalOffer.actions,
  ...inspection.actions,
  ...negotiation.actions,
  ...negotiationResponse.actions,
  ...purchaseOrder.actions,
  ...inspectionQualification.actions,
  ...inspectionInspectedComponent.actions,
  ...processTrafficTicket.actions,
  ...enablement.actions,
  ...inspectionComponent.actions,
  ...inspectionView.actions,
  ...appraisalDetail.actions,
  ...consignment.actions,
}

export const schema = { Models, actions }
export default schema
