import gql from 'graphql-tag'

export const availableKey = gql`
  query availableKey {
    response: available_key {
      id
      keyId
    }
  }
`

export const findStockId = gql`
  query findStockId($autoId: Int!) {
    stock: sales_stock(
      where: { stock_detail: { auto: { id: { _eq: $autoId } } } }
      order_by: { id: desc }
      limit: 1
    ) {
      id
    }
  }
`

export const publishStock = gql`
  mutation publishStock($stockId: Float!) {
    business_upsert_publications(id_stock: $stockId)
  }
`

export const removeStock = gql`
  mutation removeStock($stockId: Float!) {
    business_remove(id_stock: $stockId)
  }
`

export const republishStock = gql`
  mutation republishStock($stockId: Float!) {
    business_republish(id_stock: $stockId)
  }
`

export const getSaleOrdersByStockId = gql`
  query getSaleOrdersByStockId($stockId: Int!) {
    saleOrders: get_sale_orders_by_stock(idStock: $stockId) {
      id
    }
  }
`
